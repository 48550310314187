// src/components/Loading.js
import React from 'react';

export const LoadingSpinner = ({ size = 'default' }) => {
    const sizeClasses = {
        small: 'w-5 h-5',
        default: 'w-8 h-8',
        large: 'w-12 h-12'
    };

    return (
        <div className="flex justify-center items-center">
            <div className={`${sizeClasses[size]} animate-spin`}>
                <svg
                    className="w-full h-full text-teal-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    />
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                </svg>
            </div>
        </div>
    );
};

export const LoadingOverlay = ({ children, isLoading, type = 'full' }) => {
    if (!isLoading) return children;

    if (type === 'inline') {
        return <LoadingSpinner size="small" />;
    }

    return (
        <div className="relative">
            {children}
            <div className="absolute inset-0 bg-white/80 flex items-center justify-center backdrop-blur-sm">
                <LoadingSpinner size="large" />
            </div>
        </div>
    );
};

export const LoadingPage = () => (
    <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
            <LoadingSpinner size="large" />
            <p className="mt-4 text-gray-600">Loading...</p>
        </div>
    </div>
);