import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AlertCircle, CreditCard, Lock, ArrowRight } from 'lucide-react';

const UnPaidPage = () => {
    const { t } = useTranslation();

    return (
        <div className="min-h-screen bg-gray-50 py-12">
            <div className="container mx-auto px-4">
                <div className="max-w-3xl mx-auto">
                    {/* Alert Banner */}
                    <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-8">
                        <div className="flex items-center gap-3">
                            <AlertCircle className="h-5 w-5 text-red-500" />
                            <div>
                                <h2 className="text-lg font-semibold text-red-800">
                                    {t('unpaid.alert.title', 'Access Restricted')}
                                </h2>
                                <p className="text-red-700">
                                    {t('unpaid.alert.description', 'Your account requires payment to access full features.')}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Main Content Card */}
                    <div className="bg-white rounded-lg shadow-md overflow-hidden mb-8">
                        <div className="p-6">
                            <h1 className="text-2xl font-bold text-gray-900 mb-2">
                                {t('unpaid.card.title', 'Complete Your Subscription')}
                            </h1>
                            <p className="text-gray-600 mb-6">
                                {t('unpaid.card.description', 'Unlock all features and start maximizing your potential today.')}
                            </p>

                            {/* Benefits List */}
                            <div className="space-y-6">
                                <div className="space-y-4">
                                    <h3 className="text-lg font-semibold text-gray-900">
                                        {t('unpaid.benefits.title', 'What You\'ll Get')}
                                    </h3>
                                    <ul className="space-y-3">
                                        {[
                                            'Full access to all platform features',
                                            'Real-time analytics and insights',
                                            'Priority customer support',
                                            'Advanced reporting tools'
                                        ].map((benefit, index) => (
                                            <li key={index} className="flex items-center gap-3 text-gray-600">
                                                <Lock className="h-5 w-5 text-teal-600" />
                                                {t(`unpaid.benefits.item${index + 1}`, benefit)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Payment Options */}
                                <div className="bg-gray-50 p-4 rounded-lg">
                                    <div className="flex items-center gap-3 mb-4">
                                        <CreditCard className="h-5 w-5 text-teal-600" />
                                        <h3 className="text-lg font-semibold text-gray-900">
                                            {t('unpaid.payment.title', 'Secure Payment Options')}
                                        </h3>
                                    </div>
                                    <p className="text-gray-600 mb-4">
                                        {t('unpaid.payment.description', 'Choose your preferred payment method to continue')}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="border-t border-gray-200 p-6 bg-gray-50 flex flex-col sm:flex-row gap-4">
                            <Link
                                to="/payment"
                                className="w-full sm:w-auto flex items-center justify-center gap-2 bg-teal-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-teal-700 transition duration-300"
                            >
                                {t('unpaid.cta.primary', 'Proceed to Payment')}
                                <ArrowRight className="h-4 w-4" />
                            </Link>
                            <Link
                                to="/contact"
                                className="w-full sm:w-auto flex items-center justify-center gap-2 bg-white text-gray-600 px-6 py-3 rounded-lg font-semibold border border-gray-300 hover:bg-gray-50 transition duration-300"
                            >
                                {t('unpaid.cta.secondary', 'Contact Support')}
                            </Link>
                        </div>
                    </div>

                    {/* FAQ Section */}
                    <div className="text-center">
                        <p className="text-gray-600">
                            {t('unpaid.faq.text', 'Have questions about our pricing?')}
                            {' '}
                            <Link to="/faq" className="text-teal-600 hover:text-teal-700 font-medium">
                                {t('unpaid.faq.link', 'View our FAQ')}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnPaidPage;