import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const HomePage = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-gray-50">
            {/* Hero Section */}
            <section className="bg-gradient-to-r from-teal-500 to-blue-600 text-white py-20">
                <div className="container mx-auto px-4">
                    <div className="max-w-3xl mx-auto text-center">
                        <h1 className="text-4xl md:text-5xl font-bold mb-6 leading-tight">{t('home.hero.title')}</h1>
                        <p className="text-xl mb-8 text-teal-100">{t('home.hero.subtitle')}</p>
                        <Link
                            to="/signup"
                            className="bg-white text-teal-600 px-8 py-3 rounded-full font-semibold text-lg hover:bg-teal-100 transition duration-300 shadow-lg hover:shadow-xl"
                        >
                            {t('home.hero.cta')}
                        </Link>
                    </div>
                </div>
            </section>

            {/* Features Section */}
            <section className="py-20">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-gray-800">{t('home.features.title')}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        <FeatureCard
                            title={t('home.features.unifiedDashboard.title')}
                            description={t('home.features.unifiedDashboard.description')}
                            icon="📊"
                        />
                        <FeatureCard
                            title={t('home.features.realTimeAnalytics.title')}
                            description={t('home.features.realTimeAnalytics.description')}
                            icon="⚡"
                        />
                        <FeatureCard
                            title={t('home.features.smartOptimization.title')}
                            description={t('home.features.smartOptimization.description')}
                            icon="🧠"
                        />
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="bg-gray-100 py-20">
                <div className="container mx-auto px-4">
                    <div className="max-w-3xl mx-auto text-center">
                        <h2 className="text-3xl md:text-4xl font-bold mb-6 text-gray-800">{t('home.cta.title')}</h2>
                        <p className="text-xl mb-8 text-gray-600">{t('home.cta.description')}</p>
                        <Link
                            to="/signup"
                            className="bg-teal-600 text-white px-8 py-3 rounded-full font-semibold text-lg hover:bg-teal-700 transition duration-300 shadow-lg hover:shadow-xl"
                        >
                            {t('home.cta.button')}
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
};

const FeatureCard = ({ title, description, icon }) => (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
        <div className="text-4xl mb-4">{icon}</div>
        <h3 className="text-xl font-semibold mb-2 text-gray-800">{title}</h3>
        <p className="text-gray-600">{description}</p>
    </div>
);

export default HomePage;
