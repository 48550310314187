import apiClient from './apiClient';

export async function fetchGoogleAdsData() {
    try {
        const response = await apiClient.get('/accounts/google-ads-data');
        return response.data;
    } catch (error) {
        console.error('Error fetching Google Ads data:', error);
        throw error;
    }
}

// You can add other API-related functions here as well
export async function googleLogin(credentials) {
    try {
        const response = await apiClient.post('/accounts/google-login', credentials);
        return response;
    } catch (error) {
        console.error('Error fetching Google Ads data:', error);
        throw error;
    }
}
