import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import { User, CreditCard, Bell, Lock, ChevronRight } from 'lucide-react';

const AccountPage = () => {
    const { t } = useTranslation();
    const { user, isPaid } = useAuth();
    const [activeTab, setActiveTab] = useState('profile');

    const menuItems = [
        { id: 'profile', icon: User, label: 'account.tabs.profile' },
        { id: 'subscription', icon: CreditCard, label: 'account.tabs.subscription' },
        { id: 'security', icon: Lock, label: 'account.tabs.security' },
        { id: 'notifications', icon: Bell, label: 'account.tabs.notifications' }
    ];

    return (
        <div className="min-h-screen bg-gray-50 py-8">
            <div className="container mx-auto px-4">
                {/* Account Header */}
                <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-sm p-6 mb-6">
                    <div className="flex items-center space-x-4">
                        <div className="w-16 h-16 rounded-full bg-teal-600 text-white flex items-center justify-center text-2xl font-semibold">
                            {user.name?.charAt(0).toUpperCase()}
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">{user.name}</h1>
                            <p className="text-gray-500">{user.email}</p>
                            {!isPaid && (
                                <span className="inline-block mt-2 text-sm bg-yellow-100 text-yellow-800 px-3 py-1 rounded-full">
                                    {t('account.unpaidLabel')}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                {/* Main Content */}
                <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-sm">
                    <div className="md:grid md:grid-cols-4">
                        {/* Sidebar */}
                        <nav className="p-4 border-r border-gray-200">
                            {menuItems.map(({ id, icon: Icon, label }) => (
                                <button
                                    key={id}
                                    onClick={() => setActiveTab(id)}
                                    className={`w-full flex items-center space-x-2 px-4 py-2 rounded-lg text-left mb-2 ${
                                        activeTab === id
                                            ? 'bg-teal-50 text-teal-600'
                                            : 'text-gray-600 hover:bg-gray-50'
                                    }`}
                                >
                                    <Icon size={20} />
                                    <span>{t(label)}</span>
                                </button>
                            ))}
                        </nav>

                        {/* Content Area */}
                        <div className="col-span-3 p-6">
                            {activeTab === 'profile' && (
                                <div>
                                    <h2 className="text-xl font-semibold mb-6">{t('account.profile.title')}</h2>
                                    <div className="space-y-4">
                                        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                                            <div>
                                                <p className="text-sm text-gray-500">{t('account.profile.fullName')}</p>
                                                <p className="font-medium">{user.first_name}</p>
                                            </div>
                                            <ChevronRight className="text-gray-400" />
                                        </div>
                                        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                                            <div>
                                                <p className="text-sm text-gray-500">{t('account.profile.email')}</p>
                                                <p className="font-medium">{user.email}</p>
                                            </div>
                                            <ChevronRight className="text-gray-400" />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'subscription' && (
                                <div>
                                    <h2 className="text-xl font-semibold mb-6">{t('account.subscription.title')}</h2>
                                    {isPaid ? (
                                        <div className="bg-green-50 border border-green-200 rounded-lg p-4">
                                            <p className="text-green-700">{t('account.subscription.active')}</p>
                                        </div>
                                    ) : (
                                        <div className="space-y-4">
                                            <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
                                                <p className="text-yellow-700">{t('account.subscription.inactive')}</p>
                                            </div>
                                            <button className="bg-teal-600 text-white px-6 py-2 rounded-lg hover:bg-teal-700">
                                                {t('account.subscription.subscribe')}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}

                            {activeTab === 'security' && (
                                <div>
                                    <h2 className="text-xl font-semibold mb-6">{t('account.security.title')}</h2>
                                    <button className="bg-teal-600 text-white px-6 py-2 rounded-lg hover:bg-teal-700">
                                        {t('account.security.changePassword')}
                                    </button>
                                </div>
                            )}

                            {activeTab === 'notifications' && (
                                <div>
                                    <h2 className="text-xl font-semibold mb-6">{t('account.notifications.title')}</h2>
                                    <div className="space-y-4">
                                        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                                            <div>
                                                <p className="font-medium">{t('account.notifications.emailUpdates')}</p>
                                                <p className="text-sm text-gray-500">{t('account.notifications.emailDescription')}</p>
                                            </div>
                                            <input
                                                type="checkbox"
                                                className="form-checkbox h-5 w-5 text-teal-600 rounded"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountPage;