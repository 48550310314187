import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import {setTokens, clearTokens, baseApiURL} from '../services/apiClient';
import { LoadingPage } from '../components/Loading';

const AuthContext = createContext(null);

export const PAYMENT_STATUS = {
    PAID: 'paid',
    UNPAID: 'unpaid',
    PENDING: 'pending'
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null);

    const checkPaymentStatus = async (token) => {
        try {
            const response = await axios.get(`${baseApiURL}/api/payment-status/`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setPaymentStatus(response.data.status);
            return response.data.status;
        } catch (error) {
            console.error('Error checking payment status:', error);
            setPaymentStatus(PAYMENT_STATUS.UNPAID);
            return PAYMENT_STATUS.UNPAID;
        }
    };

    useEffect(() => {
        // Check if user is logged in on mount
        const checkLoggedIn = async () => {
            const token = localStorage.getItem('access_token');
            if (token) {
                try {
                    const response = await axios.get(`${baseApiURL}/api/user/me`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setUser(response.data);

                    // Check payment status after confirming user is logged in
                    await checkPaymentStatus(token);
                } catch (error) {
                    console.error('Error fetching user', error);
                    // Clear everything if token is invalid
                    handleLogout();
                }
            }
            setLoading(false);
        };

        checkLoggedIn();
    }, []);

    const login = async (credentials, isGoogleLogin = false) => {
        setIsAuthenticating(true);
        try {
            let response;
            if (isGoogleLogin) {
                response = await axios.post(`${baseApiURL}/api/accounts/google-login`, { access_token: credentials });
            } else {
                response = await axios.post(`${baseApiURL}/api/accounts/login`, credentials);
            }

            const { access, refresh } = response.data.tokens;

            // Set tokens in localStorage and apiClient
            localStorage.setItem('access_token', access);
            localStorage.setItem('refresh_token', refresh);
            setTokens(access, refresh);

            setUser(response.data.user);

            // Check payment status after successful login
            await checkPaymentStatus(access);

            return response.data;
        } catch (error) {
            console.error('Login error:', error);
            return false;
        } finally {
            setIsAuthenticating(false);
        }
    };

    const handleLogout = async () => {
        try {
            // Optional: Call logout endpoint if your backend requires it
            const token = localStorage.getItem('access_token');
            if (token) {
                try {
                    await axios.post(`${baseApiURL}/api/accounts/logout/`, null, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                } catch (error) {
                    console.error('Logout endpoint error:', error);
                    // Continue with local cleanup even if server logout fails
                }
            }
        } finally {
            // Clear all auth states and storage
            setUser(null);
            setPaymentStatus(null);
            clearTokens(); // This should clear tokens from apiClient
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');

            // Clear any other auth-related items you might have in localStorage
            // localStorage.clear(); // Use this if you want to clear everything

            // Force reload the page to clear any cached states
            window.location.href = '/login';
        }
    };

    const value = {
        user,
        login,
        logout: handleLogout, // Renamed for clarity
        loading,
        isAuthenticating,
        paymentStatus,
        isPaid: paymentStatus === PAYMENT_STATUS.PAID,
        isAuthenticated: !!user,
        checkPaymentStatus: () => checkPaymentStatus(localStorage.getItem('access_token'))
    };

    if (loading) {
        return <LoadingPage />;
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

// Custom hook for protected routes
export const useProtectedRoute = () => {
    const { user, loading, isPaid } = useAuth();
    const protectionLevel = {
        requiresAuth: !loading && !user,
        requiresPayment: !loading && user && !isPaid
    };
    return protectionLevel;
};