import React from 'react';
import { useAuth } from '../contexts/AuthContext';

function Dashboard() {
    const { user, logout } = useAuth();

    return (
        <div>
            <h1>Welcome to the Dashboard, {user?.first_name}!</h1>
            <button onClick={logout}>Logout</button>
        </div>
    );
}

export default Dashboard;
