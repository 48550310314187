import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import { User, LogOut } from 'lucide-react';
import Logo from './Logo';

const Header = () => {
    const { t, i18n } = useTranslation();
    const { user, logout } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);

    const toggleMenu = () => setIsOpen(!isOpen);
    const toggleUserMenu = () => setShowUserMenu(!showUserMenu);

    const closeMenu = () => {
        setIsOpen(false);
        setShowUserMenu(false);
    };

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        closeMenu();
    };

    const handleLogout = async () => {
        await logout();
        closeMenu();
    };

    // User menu component
    const UserMenuContent = ({ mobile }) => (
        <div className={mobile ? "space-y-3" : "absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-lg z-50"}>
            <div className={`px-4 py-2 border-b border-gray-100 ${mobile ? 'border-none' : ''}`}>
                <p className="text-sm font-medium text-gray-900">{user.name}</p>
                <p className="text-sm text-gray-500">{user.email}</p>
            </div>
            <Link
                to="/account"
                className={`${mobile ? 'block py-2' : 'block px-4 py-2 hover:bg-gray-50'} text-sm text-gray-700`}
                onClick={closeMenu}
            >
                <div className="flex items-center space-x-2">
                    <User size={16} />
                    <span>{t('navigation.account')}</span>
                </div>
            </Link>
            <button
                onClick={handleLogout}
                className={`${mobile ? 'block py-2 w-full text-left' : 'block px-4 py-2 w-full text-left hover:bg-gray-50'} text-sm text-gray-700`}
            >
                <div className="flex items-center space-x-2">
                    <LogOut size={16} />
                    <span>{t('navigation.logout')}</span>
                </div>
            </button>
        </div>
    );

    return (
        <header className="bg-white shadow-md">
            <div className="container mx-auto px-4">
                <div className="flex items-center justify-between py-3">
                    <Link to="/" className="flex items-center" onClick={closeMenu}>
                        <Logo className="h-8 w-auto" />
                        <span className="ml-2 text-xl font-semibold text-gray-800">ACR</span>
                    </Link>

                    {/* Mobile menu button */}
                    <button
                        onClick={toggleMenu}
                        className="md:hidden text-gray-600 focus:outline-none"
                    >
                        <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
                        </svg>
                    </button>

                    {/* Desktop menu */}
                    <nav className="hidden md:flex items-center space-x-6">
                        <NavLinks />
                        <LanguageSelector changeLanguage={changeLanguage} />
                        {user ? (
                            <div className="relative">
                                <button
                                    onClick={toggleUserMenu}
                                    className="flex items-center space-x-2 text-gray-700 hover:text-teal-600 focus:outline-none group"
                                >
                                    <div className="w-8 h-8 rounded-full bg-gradient-to-r from-teal-500 to-blue-500 text-white flex items-center justify-center shadow-md transition-transform group-hover:scale-105">
                                        {user.first_name?.charAt(0).toUpperCase()}
                                    </div>
                                    <span className="text-sm font-medium">{user.name}</span>
                                    <svg
                                        className="w-4 h-4 transition-transform group-hover:translate-y-0.5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>
                                {showUserMenu && <UserMenuContent />}
                            </div>
                        ) : (
                            <Link
                                to="/login"
                                className="bg-gradient-to-r from-teal-500 to-blue-500 hover:from-teal-600 hover:to-blue-600 text-white text-sm font-medium py-2 px-6 rounded-full shadow-md hover:shadow-lg transition-all duration-200 transform hover:-translate-y-0.5"
                            >
                                {t('navigation.login')}
                            </Link>
                        )}
                    </nav>
                </div>

                {/* Mobile menu */}
                {isOpen && (
                    <div className="md:hidden">
                        <nav className="py-3 space-y-2">
                            <NavLinks mobile closeMenu={closeMenu} />
                            <LanguageSelector mobile changeLanguage={changeLanguage} />
                            {user ? (
                                <UserMenuContent mobile />
                            ) : (
                                <Link
                                    to="/login"
                                    className="block bg-gradient-to-r from-teal-500 to-blue-500 hover:from-teal-600 hover:to-blue-600 text-white text-sm font-medium py-2 px-4 rounded-full shadow-md hover:shadow-lg transition-all duration-200"
                                    onClick={closeMenu}
                                >
                                    {t('navigation.login')}
                                </Link>
                            )}
                        </nav>
                    </div>
                )}
            </div>
        </header>
    );
};

const NavLinks = ({ mobile = false, closeMenu = () => {} }) => {
    const { t } = useTranslation();
    const linkClass = mobile
        ? "block py-2 text-gray-600 hover:text-teal-600"
        : "text-sm font-medium text-gray-600 hover:text-teal-600";

    return (
        <>
            <Link to="/solutions" className={linkClass} onClick={closeMenu}>{t('navigation.solutions')}</Link>
            <Link to="/contact" className={linkClass} onClick={closeMenu}>{t('navigation.contactSales')}</Link>
        </>
    );
};

const LanguageSelector = ({ mobile = false, changeLanguage }) => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleLanguageChange = (lang) => {
        changeLanguage(lang);
        setIsOpen(false);
    };

    const buttonClass = mobile
        ? "w-full text-left py-2 text-gray-600 hover:text-teal-600"
        : "flex items-center text-sm font-medium text-gray-600 hover:text-teal-600 focus:outline-none";

    return (
        <div className={mobile ? "" : "relative"}>
            <button onClick={toggleDropdown} className={buttonClass}>
                <span className="mr-1">{i18n.language === 'en' ? '🇺🇸' : '🇻🇳'}</span>
                {mobile ? (i18n.language === 'en' ? 'English' : 'Tiếng Việt') : (
                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                )}
            </button>
            {isOpen && (
                <div className={mobile ? "mt-2" : "absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20"}>
                    <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-teal-500 hover:text-white"
                        onClick={() => handleLanguageChange('en')}
                    >
                        🇺🇸 {t('languages.en')}
                    </a>
                    <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-teal-500 hover:text-white"
                        onClick={() => handleLanguageChange('vi')}
                    >
                        🇻🇳 {t('languages.vi')}
                    </a>
                </div>
            )}
        </div>
    );
};

export default Header;