import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from './ui/card';
import { AlertTriangle, BarChart3, Brain, Clock, Target, Zap } from 'lucide-react';

const SolutionsPage = () => {
    const { t } = useTranslation();

    const solutions = [
        {
            icon: <BarChart3 className="w-12 h-12 text-teal-500" />,
            key: "campaignAnalytics"
        },
        {
            icon: <AlertTriangle className="w-12 h-12 text-blue-500" />,
            key: "anomalyDetection"
        },
        {
            icon: <Brain className="w-12 h-12 text-teal-500" />,
            key: "smartOptimization"
        },
        {
            icon: <Clock className="w-12 h-12 text-blue-500" />,
            key: "automatedReporting"
        },
        {
            icon: <Target className="w-12 h-12 text-teal-500" />,
            key: "campaignManagement"
        },
        {
            icon: <Zap className="w-12 h-12 text-blue-500" />,
            key: "quickActions"
        }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-teal-400 via-cyan-500 to-blue-600">
            <div className="container mx-auto px-4 py-12">
                {/* Hero Section */}
                <div className="text-center mb-16">
                    <h1 className="text-4xl font-bold mb-4 text-white">
                        {t('solutions.title')}
                    </h1>
                    <p className="text-xl text-white/90 max-w-3xl mx-auto">
                        {t('solutions.subtitle')}
                    </p>
                </div>

                {/* Solutions Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {solutions.map((solution, index) => (
                        <Card key={index} className="bg-white/95 backdrop-blur-lg hover:shadow-2xl hover:scale-105 transition-all duration-300 border-none">
                            <CardContent className="p-8">
                                <div className="flex flex-col items-center text-center">
                                    <div className="mb-4 transform hover:scale-110 transition-transform duration-300">
                                        {solution.icon}
                                    </div>
                                    <h3 className="text-xl font-semibold mb-3 text-gray-800">
                                        {t(`solutions.cards.${solution.key}.title`)}
                                    </h3>
                                    <p className="text-gray-600 mb-6">
                                        {t(`solutions.cards.${solution.key}.description`)}
                                    </p>
                                    <ul className="text-left w-full">
                                        {t(`solutions.cards.${solution.key}.features`, { returnObjects: true }).map((feature, idx) => (
                                            <li key={idx} className="flex items-center mb-3">
                                                <div className="w-2 h-2 bg-gradient-to-r from-teal-400 to-blue-500 rounded-full mr-2"></div>
                                                <span className="text-gray-700">{feature}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>

                {/* Call to Action */}
                <div className="text-center mt-16">
                    <h2 className="text-3xl font-bold mb-4 text-white">
                        {t('solutions.cta.title')}
                    </h2>
                    <p className="text-xl text-white/90 mb-8">
                        {t('solutions.cta.subtitle')}
                    </p>
                    <button className="bg-white text-blue-600 px-8 py-3 rounded-lg hover:bg-blue-50 transition-colors duration-300 font-semibold shadow-lg hover:shadow-xl">
                        {t('solutions.cta.button')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SolutionsPage;
