import React from 'react';
import logo from '../assets/acr-logo.svg';

const Logo = ({ className = '' }) => {
    return (
        <img
            src={logo}
            alt="ACR Logo"
            className={`w-12 h-12 object-contain ${className}`}
            style={{ maxWidth: '10rem', maxHeight: '10rem' }}
        />
    );
};

export default Logo;
