import React, { useState } from 'react';
import { Card, CardContent } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import { Mail, Phone, Send, AlertCircle, CheckCircle2, XCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const ContactSalesPage = () => {
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: '',
        preferredContact: 'email'
    });

    const [alertState, setAlertState] = useState({
        show: false,
        type: '', // 'success' or 'error'
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const validateForm = () => {
        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            setAlertState({
                show: true,
                type: 'error',
                message: t('contact.form.validation.emailInvalid')
            });
            return false;
        }

        // Phone validation (if provided)
        if (formData.phone) {
            const phoneRegex = /^\+?[0-9]{10,}$/;
            if (!phoneRegex.test(formData.phone.replace(/[\s-]/g, ''))) {
                setAlertState({
                    show: true,
                    type: 'error',
                    message: t('contact.form.validation.phoneInvalid')
                });
                return false;
            }
        }

        // Message length validation
        if (formData.message.length < 10) {
            setAlertState({
                show: true,
                type: 'error',
                message: t('contact.form.validation.messageLength')
            });
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            // Simulating API call
            // Replace this with your actual API call
            await new Promise(resolve => setTimeout(resolve, 1000));

            setAlertState({
                show: true,
                type: 'success',
                message: t('contact.form.successMessage')
            });

            // Clear form after successful submission
            setFormData({
                name: '',
                email: '',
                phone: '',
                company: '',
                message: '',
                preferredContact: 'email'
            });

            // Hide success message after 5 seconds
            setTimeout(() => {
                setAlertState(prev => ({ ...prev, show: false }));
            }, 5000);

        } catch (error) {
            setAlertState({
                show: true,
                type: 'error',
                message: t('contact.form.errorMessage')
            });
        }
    };

    const getAlertStyles = (type) => {
        const baseStyles = "mb-6 border transition-all duration-300";
        switch (type) {
            case 'success':
                return `${baseStyles} bg-green-50 border-green-200 text-green-800`;
            case 'error':
                return `${baseStyles} bg-red-50 border-red-200 text-red-800`;
            default:
                return baseStyles;
        }
    };

    const getAlertIcon = (type) => {
        switch (type) {
            case 'success':
                return <CheckCircle2 className="h-4 w-4 text-green-600" />;
            case 'error':
                return <XCircle className="h-4 w-4 text-red-600" />;
            default:
                return <AlertCircle className="h-4 w-4" />;
        }
    };

    const contactMethods = [
        {
            icon: <Mail className="w-6 h-6 text-teal-500" />,
            key: 'email'
        },
        {
            icon: <Phone className="w-6 h-6 text-blue-500" />,
            key: 'phone'
        }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-teal-400 via-cyan-500 to-blue-600">
            <div className="container mx-auto px-4 py-12">
                {/* Header section remains the same */}
                <div className="text-center mb-16">
                    <h1 className="text-4xl font-bold mb-4 text-white">{t('contact.title')}</h1>
                    <p className="text-xl text-white/90 max-w-3xl mx-auto">{t('contact.subtitle')}</p>
                </div>

                {/* Enhanced Alert */}
                {alertState.show && (
                    <Alert className={getAlertStyles(alertState.type)}>
                        <div className="flex items-center gap-2">
                            {getAlertIcon(alertState.type)}
                            <AlertDescription className="text-sm font-medium">
                                {alertState.message}
                            </AlertDescription>
                        </div>
                    </Alert>
                )}

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                    {/* Contact Form */}
                    <Card className="lg:col-span-2 bg-white/95 backdrop-blur-lg">
                        <CardContent className="p-8">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    {/* Name Field */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            {t('contact.form.name')}
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            placeholder={t('contact.form.namePlaceholder')}
                                            required
                                        />
                                    </div>

                                    {/* Company Field */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            {t('contact.form.company')}
                                        </label>
                                        <input
                                            type="text"
                                            name="company"
                                            value={formData.company}
                                            onChange={handleInputChange}
                                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            placeholder={t('contact.form.companyPlaceholder')}
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    {/* Email Field */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            {t('contact.form.email')}
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            placeholder={t('contact.form.emailPlaceholder')}
                                            required
                                        />
                                    </div>

                                    {/* Phone Field */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            {t('contact.form.phone')}
                                        </label>
                                        <input
                                            type="tel"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            placeholder={t('contact.form.phonePlaceholder')}
                                        />
                                    </div>
                                </div>

                                {/* Preferred Contact Method */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        {t('contact.form.preferredContact')}
                                    </label>
                                    <div className="flex gap-4">
                                        {['email', 'phone'].map((method) => (
                                            <label key={method} className="flex items-center">
                                                <input
                                                    type="radio"
                                                    name="preferredContact"
                                                    value={method}
                                                    checked={formData.preferredContact === method}
                                                    onChange={handleInputChange}
                                                    className="mr-2"
                                                />
                                                <span className="capitalize">{t(`contact.form.methods.${method}`)}</span>
                                            </label>
                                        ))}
                                    </div>
                                </div>

                                {/* Message Field */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        {t('contact.form.message')}
                                    </label>
                                    <textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        rows="4"
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder={t('contact.form.messagePlaceholder')}
                                        required
                                    />
                                </div>

                                <button
                                    type="submit"
                                    className="w-full bg-gradient-to-r from-teal-400 to-blue-500 text-white py-3 px-6 rounded-lg hover:from-teal-500 hover:to-blue-600 transition-all duration-300 flex items-center justify-center gap-2"
                                >
                                    <Send className="w-5 h-5" />
                                    {t('contact.form.submit')}
                                </button>
                            </form>
                        </CardContent>
                    </Card>

                    {/* Contact Methods */}
                    <div className="space-y-6">
                        {contactMethods.map((method) => (
                            <Card key={method.key} className="bg-white/95 backdrop-blur-lg">
                                <CardContent className="p-6">
                                    <div className="flex items-start space-x-4">
                                        <div className="p-3 bg-gray-50 rounded-lg">
                                            {method.icon}
                                        </div>
                                        <div>
                                            <h3 className="font-semibold text-lg mb-1">
                                                {t(`contact.contactMethods.${method.key}.title`)}
                                            </h3>
                                            <p className="text-gray-600 text-sm mb-2">
                                                {t(`contact.contactMethods.${method.key}.description`)}
                                            </p>
                                            <p className="text-blue-600 font-medium">
                                                {t(`contact.contactMethods.${method.key}.value`)}
                                            </p>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSalesPage;
