// src/components/RouteGuards.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

// For routes that require both authentication and payment
export const ProtectedRoute = ({ children }) => {
    const { user, loading, isPaid } = useAuth();
    const location = useLocation();

    if (loading) {
        return <div>Loading...</div>; // Or your loading component
    }

    if (!user) {
        // Save the attempted url for redirecting after login
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!isPaid) {
        return <Navigate to="/unpaid" replace />;
    }

    return children;
};

// For routes that require only authentication (like the unpaid page)
export const AuthRoute = ({ children }) => {
    const { user, loading } = useAuth();
    const location = useLocation();

    if (loading) {
        return <div>Loading...</div>; // Or your loading component
    }

    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

// For routes that should not be accessible when logged in (like login page)
export const PublicRoute = ({ children }) => {
    const { user, loading } = useAuth();
    const location = useLocation();

    if (loading) {
        return <div>Loading...</div>; // Or your loading component
    }

    if (user) {
        // Redirect to the page they came from or dashboard
        const from = location.state?.from?.pathname || '/dashboard';
        return <Navigate to={from} replace />;
    }

    return children;
};
