import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="bg-gradient-to-r from-teal-600 to-blue-700 text-white py-12">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    <div className="space-y-4">
                        <Logo className="h-8 w-auto brightness-0 invert" />
                        <p className="text-teal-100">{t('footer.companyDescription')}</p>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4 text-teal-100">{t('footer.quickLinks')}</h3>
                        <ul className="space-y-2">
                            <li><Link to="/about" className="hover:text-teal-200 transition duration-300">{t('footer.aboutUs')}</Link></li>
                            <li><Link to="/features" className="hover:text-teal-200 transition duration-300">{t('footer.features')}</Link></li>
                            <li><Link to="/pricing" className="hover:text-teal-200 transition duration-300">{t('footer.pricing')}</Link></li>
                            <li><Link to="/contact" className="hover:text-teal-200 transition duration-300">{t('footer.contact')}</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4 text-teal-100">{t('footer.connectWithUs')}</h3>
                        <ul className="space-y-2">
                            <li><a href="https://twitter.com/yourcompany" className="flex items-center hover:text-teal-200 transition duration-300">
                                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>
                                Twitter
                            </a></li>
                            <li><a href="https://facebook.com/yourcompany" className="flex items-center hover:text-teal-200 transition duration-300">
                                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
                                Facebook
                            </a></li>
                            <li><a href="https://linkedin.com/company/yourcompany" className="flex items-center hover:text-teal-200 transition duration-300">
                                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
                                LinkedIn
                            </a></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4 text-teal-100">{t('footer.newsletter')}</h3>
                        <form className="space-y-2">
                            <input
                                type="email"
                                placeholder={t('footer.emailPlaceholder')}
                                className="w-full px-3 py-2 bg-teal-700 text-white placeholder-teal-300 rounded focus:outline-none focus:ring-2 focus:ring-teal-300"
                            />
                            <button
                                type="submit"
                                className="w-full bg-teal-500 hover:bg-teal-400 text-white font-bold py-2 px-4 rounded transition duration-300"
                            >
                                {t('footer.subscribe')}
                            </button>
                        </form>
                    </div>
                </div>
                <div className="mt-8 pt-8 border-t border-teal-500 text-center">
                    <p className="text-teal-200">{t('footer.copyright')}</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;