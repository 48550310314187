import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './contexts/AuthContext';
import Header from './components/Header';
import HomePage from './components/HomePage';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import {AuthRoute, ProtectedRoute, PublicRoute} from './components/RouteGuard';
import './App.css';
import Footer from "./components/Footer";
import './i18n';
import UnPaidPage from "./components/UnPaidPage";
import AccountPage from "./components/AccountPage";
import SolutionsPage from "./components/SolutionsPage";
import ContactSalesPage from "./components/ContactSalesPage";

function App() {
    const clientId = window._env_ ? window._env_.REACT_APP_GOOGLE_CLIENT_ID : process.env.REACT_APP_GOOGLE_CLIENT_ID;

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <AuthProvider>
                <Router>
                    <div className="flex flex-col min-h-screen">
                        <Header />
                        <main className="flex-grow mt-0"> {/* Add top margin to account for fixed header */}
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/login" element={
                                    <PublicRoute>
                                        <LoginPage />
                                    </PublicRoute>
                                } />

                                <Route path="/contact" element={
                                    <PublicRoute>
                                        <ContactSalesPage />
                                    </PublicRoute>
                                } />

                                <Route path="/solutions" element={
                                    <PublicRoute>
                                        <SolutionsPage />
                                    </PublicRoute>
                                } />

                                {/* Auth-only routes */}
                                <Route path="/unpaid" element={
                                    <AuthRoute>
                                        <UnPaidPage />
                                    </AuthRoute>
                                } />

                                <Route path="/account"
                                        element={
                                        <AuthRoute>
                                            <AccountPage />
                                        </AuthRoute>
                                    }/>

                                {/* Protected routes (require auth + payment) */}
                                <Route path="/dashboard" element={
                                    <ProtectedRoute>
                                        <Dashboard />
                                    </ProtectedRoute>
                                } />
                            </Routes>
                        </main>
                        <Footer />
                    </div>
                </Router>
            </AuthProvider>
        </GoogleOAuthProvider>
    );
}

export default App;