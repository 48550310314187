// src/services/apiClient.js
import axios from 'axios';

export const baseApiURL = "https://acrgroups.com"

const apiClient = axios.create({
    baseURL: baseApiURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

let accessToken = null;
let refreshToken = null;
let userPaymentStatus = null;

// Status constants
export const PAYMENT_STATUS = {
    PAID: 'paid',
    UNPAID: 'unpaid',
    PENDING: 'pending'
};

export const AUTH_STATUS = {
    AUTHENTICATED: 'authenticated',
    UNAUTHENTICATED: 'unauthenticated',
    LOADING: 'loading'
};

export const setTokens = (access, refresh) => {
    console.log(access, refresh);
    accessToken = access;
    refreshToken = refresh;
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh);
};

export const clearTokens = () => {
    accessToken = null;
    refreshToken = null;
    userPaymentStatus = null;
    delete apiClient.defaults.headers.common['Authorization'];
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
};

export const checkPaymentStatus = async () => {
    try {
        const response = await apiClient.get('api/payment-status/');
        userPaymentStatus = response.data.status;
        return userPaymentStatus;
    } catch (error) {
        console.error('Error checking payment status:', error);
        throw error;
    }
};

export const getCurrentPaymentStatus = () => userPaymentStatus;
export const isAuthenticated = () => !!accessToken;

const refreshAccessToken = async () => {
    try {
        const response = await axios.post(`${baseApiURL}/api/accounts/google-refresh/`, { refresh: refreshToken });
        const { access } = response.data;
        setTokens(access, refreshToken);  // We keep the same refresh token
        return access;
    } catch (error) {
        console.error('Error refreshing token:', error);
        clearTokens();
        throw error;
    }
};

// Protected routes that require authentication and payment
const PROTECTED_ROUTES = [
    '/dashboard',
    '/analytics',
    '/reports',
    '/campaigns'
];

// Routes that require only authentication
const AUTH_ONLY_ROUTES = [
    '/unpaid',
    '/profile',
    '/settings'
];

const isProtectedRoute = (url) => {
    return PROTECTED_ROUTES.some(route => url.includes(route));
};

const isAuthOnlyRoute = (url) => {
    return AUTH_ONLY_ROUTES.some(route => url.includes(route));
};

apiClient.interceptors.request.use(
    async (config) => {
        // Check authentication for protected and auth-only routes
        if ((isProtectedRoute(config.url) || isAuthOnlyRoute(config.url)) && !accessToken) {
            throw new Error('AUTHENTICATION_REQUIRED');
        }

        if (accessToken && isTokenExpired(accessToken)) {
            try {
                accessToken = await refreshAccessToken();
            } catch (error) {
                clearTokens();
                throw new Error('AUTHENTICATION_REQUIRED');
            }
        }

        // Check payment status for protected routes
        if (isProtectedRoute(config.url) && userPaymentStatus === PAYMENT_STATUS.UNPAID) {
            throw new Error('PAYMENT_REQUIRED');
        }

        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Handle authentication required error
        if (error.message === 'AUTHENTICATION_REQUIRED') {
            window.location.href = '/login';
            return Promise.reject(error);
        }

        // Handle payment required error
        if (error.response?.status === 402 || error.message === 'PAYMENT_REQUIRED') {
            window.location.href = '/unpaid';
            return Promise.reject(error);
        }

        // Handle authentication errors
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                accessToken = await refreshAccessToken();
                originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                return apiClient(originalRequest);
            } catch (refreshError) {
                clearTokens();
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

const isTokenExpired = (token) => {
    if (!token) return true;
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
};

export default apiClient;
